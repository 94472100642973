// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

.App-header {
  .wrapper {
    padding-left: 0 !important;
  }
}

.searchbar {
  width: 18rem;
  padding: 0.3rem;
  border: none;
  border-radius: 100rem;
  padding-top: 0.5rem;
  margin: 0.5rem;
  box-shadow: none;
  outline: none;
  box-shadow: 0 3px 10px rgb(0 0 0 / 50%);
}

.forgot {
  justify-content: end;
}

.cards {
  background-color: #111118;
  width: 275px;
  text-align: center;
  color: #000000;
  align-self: center;
  justify-content: center;
  align-items: center;
}

.cards {
  .card-icons {
    height: 80px;
  }
}

.modal {
  margin: 0.6rem;
  padding: 0.5rem 1.5rem;
  color: #ffffff;
  font-weight: bold;
  font-size: 0.7rem;
}

.modal-btn {
  margin: 0.6rem;
  padding: 0.5rem 1.5rem;
  background-color: #111118;
  color: #ffffff;
  font-weight: bold;
  font-size: 0.7rem;
}

.login-text {
  color: linear-gradient(to right, #fa5e51 0%, #fda085 45%, #f6d365 100%);
}

.update-btn {
  height: 40px;
  width: 80px;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 2rem 0;
}

.text-center {
  .admin-image {
    width: 150px;
    height: 100px;
    // margin-bottom: 1rem;
  }
}

.admin-details {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.forgot {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1rem;
}
